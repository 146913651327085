/* Box sizing rules */
*,
*::before,
*::after {
   box-sizing: border-box;
}

/* Remove default margin */
*{
   margin: 0;
   padding: 0;
   font: inherit; // resets font sizes of all elements
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
   list-style: none;
   padding: 0;
   margin: 0;
}

/* Set core root defaults */
html:focus-within {
   scroll-behavior: smooth;
}

/* Set core body defaults */
body {
   min-height: 100vh;
   text-rendering: optimizeSpeed;
   line-height: 1.4;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
   text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
   max-width: 100%;
   display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
   html:focus-within {
      scroll-behavior: auto;
   }
   
   *,
   *::before,
   *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
   }
}
